// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import './mdb'
import '@fortawesome/fontawesome-free/js/all'
import 'bootstrap'

var jQuery = require('jquery')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import '../stylesheets/application'

import flatpickr from 'flatpickr';
import { Japanese } from 'flatpickr/dist/l10n/ja.js'

flatpickr.localize(flatpickr.l10ns.ja);
document.addEventListener("turbolinks:load", () => {
  flatpickr(".flatpickr", {
    locale: Japanese
  })
})

global.toastr = require("toastr")

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
const images = require.context('../images', true)

Rails.start()
Turbolinks.start()
ActiveStorage.start()
